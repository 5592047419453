@import url('https://use.typekit.net/tps7ijz.css');

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: var(--border-radius);
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  height: 90vh;
  width: 100%;
  box-sizing: border-box;
  gap: 3vh;
  padding: 3vh;
  direction: ltr;
  z-index: 11;
}
.x-button {
  padding: 2dvh;
  cursor: pointer;
}

.terms-text {
  font-size: 1.2rem;
  font-weight: var(--text-font-weight);
  color: #63747c;
  opacity: var(--text-opcaity);
  direction: rtl;
  padding: 0 5vh;
  line-height: 2.5vh;
  font-family: 'narkiss tam', sans-serif;
  overflow: auto;
}

.close-button-container {
  direction: rtl;
  width: 100%;
}
/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Styling the track */
::-webkit-scrollbar-track {
  background: var(--primary-color-extra-light);
  border-radius: 10px;
}

/* Styling the thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box; /* This prevents the background color from spilling outside the border */
}

/* Remove buttons */
::-webkit-scrollbar-button {
  display: none;
}
p {
  margin-bottom: 2vh;
}
.section {
  font-size: 1.2rem;
  display: flex;
}
.section-text {
  font-size: 1.2rem;
  padding: 0 1dvh;
}
