.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1 / 1;
  width: 100%;
  margin: 7% 0;
  position: relative;
  border-radius: var(--border-radius);
  z-index: 9999;
}

.hidden {
  display: none;
}

.portrait-camera-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.videoFeed {
  transition: border-radius 0.5s ease-out;
  display: block;
  object-fit: cover;
  transform: scale(-1, 1);
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: var(--border-radius);
  z-index: 999;
}

.videoFeed-invisible {
  /* position: absolute; */
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-1000px, -1000px);
}

.videoFeed-preview {
  border-radius: var(--border-radius);
  object-fit: cover;
  width: 100%;
  transform: scale(-1, 1);
  aspect-ratio: 1 / 1;
}
