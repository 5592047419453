.gallery-container {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  aspect-ratio: 1 / 1;
  grid-gap: 2%;
  box-sizing: border-box;
  top: -1.8%;
  direction: ltr;
}
.back-container {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  top: 1vh;
  direction: ltr;
}
.grid-ghost {
  height: 60vh;
}
.output-gallery-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5vh;
}
.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  border-radius: var(--border-radius);
}
.details {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.card-title {
  color: var(--title-font-color);
  font-size: 1rem;
  font-weight: var(--title-font-weight);
}
.card-text {
  color: var(--text-font-color);
  opacity: var(--text-opcaity);
  font-weight: var(--text-font-weight);
  font-size: 0.7rem;
}
.gallery-image {
  cursor: pointer;
  width: 100%;
  /* Makes each image fill its cell */
  height: auto;
  /* Keeps the aspect ratio of each image */
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: var(--border-radius);
  /* Ensures the images cover the cell area without distortion */
}
