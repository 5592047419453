.input-name-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5vh;
}

.input-field:focus {
  outline: none;
}
