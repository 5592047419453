.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20vh, 1fr));
  gap: 1vh;
  padding: 3vh;
  overflow-y: scroll; /* Enable vertical scrolling */
  height: 95vh;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.2vh solid #ccc;
  padding: 1vh;
  box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.1);
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

.admin-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  margin-top: 1vh;
}

.text p {
  margin: 0.5vh 0;
}
