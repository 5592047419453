@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter:500,300,200,600&display=swap');
@import url('https://use.typekit.net/tps7ijz.css');

.App {
  /* text-align: left; */
  font-family: 'narkiss-tam', sans-serif;
  --font-family: 'narkiss-tam', sans-serif;
  font-optical-sizing: auto;
  --title-font-color: #354851;
  --text-font-color: #354851;
  --action-button-primary: #08141d;
  --border-radius: 0.5dvh;
  --title-font-size: 5.6dvh;
  --text-font-size: 2.6dvh;
  --button-font-size: 2.1dvh;
  --title-font-weight: 500;
  --text-font-weight: 400;
  --text-opcaity: 0.75;
  --input-field-border-color: rgba(124, 141, 154, 0.3);
  --input-field-height: 1.5dvh;
  --inverted-button-color: #5c8aa3;
  --parent-container-width: 85%;
  --primary-color: #42718b;
  --primary-color-extra-light: #e7f1f6;
  --color-disabled: #d0d9dd;
  --letter-spacing: -0.116dvh;
  --line-height: 6.17dvh;
  --terms-title-color: #63747c;
  --terms-subtitle-color: #7c8d94;
  --secondary-color: #78a2b9;
}

.input-field {
  border-radius: var(--border-radius);
  height: 7.8dvh;
  width: 100%;
  background-color: transparent;
  border-color: var(--input-field-border-color);
  opacity: 1;
  padding: 0 2dvh;
  border: 0.3dvh solid var(--input-field-border-color);
  color: black;
  padding-right: 1rem;
  box-sizing: border-box;
  font-size: 1.5rem !important;
}

.input-field::placeholder {
  color: rgba(124, 141, 154);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 300ms ease-in;
}

.stages-scroller {
  z-index: 999;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  scrollbar-width: none;
  overflow-x: hidden;
  overscroll-behavior-inline: contain;
  align-items: center;
  height: 100%;
  width: 100%;
}

.darken {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100dvh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 10; */
}

.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding: 0;
  box-sizing: border-box;
}

.snaps-inline > * {
  scroll-snap-align: center;
  position: relative;
  overflow-y: hidden;
  z-index: 999;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow-x: hidden;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10dvh 2dvh 0 2dvh;
}

.slide-in-appear {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-in-appear.slide-appear-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.slide-in-enter {
  position: absolute;
  transform: translateX(100%);
  opacity: 0;
}
.slide-in-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms, opacity 500ms;
}

/* Starting state of exit animation */
.slide-in-exit {
  transform: translateX(0);
  opacity: 1;
}

/* End state of exit animation */
.slide-in-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}

.slide-appear {
  /* transform: translateX(-100%); */
  position: absolute;
  opacity: 0;
}
.slide-appear.slide-appear-active {
  /* transform: translateX(0); */
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.slide-enter {
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

/* End state of enter animation */
.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
}

/* Starting state of exit animation */
.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

/* End state of exit animation */
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.action-buttons-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 100%;
  width: 100%;
  gap: 2dvh;
}
.action-button-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 100%;
  width: 100%;
  gap: 2dvh;
}

.singular-button {
  cursor: pointer;
  background-color: var(--action-button-primary);
  border: solid transparent;
  color: #f4f4f4;
  font-weight: 700;
  font-size: var(--button-font-size);
  border-radius: var(--border-radius);
  /* line-height: 2.5dvh; */
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1.2dvh;
  align-items: flex-end; /* Align items to the bottom */
  flex-direction: row;
  padding: 1.5rem;
  height: 100%; /* Set the height to fill the container */
  position: relative;
}

.singular-button:disabled {
  background-color: var(--color-disabled);
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.semi-transparent {
  opacity: 0.5;
}

.horizontaly-center {
  position: relative;
  top: 3dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  gap: 3dvh;
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stage-container{
  display: flex;
  justify-content: center;
}

.protrait-alligment {
  /* as it turns out this is the absolutly mosty importany setting in the whole application */
  position: fixed;
  z-index: 0;
  height: 100%;
  /* height: 100dvh; */
  top: 0;
  aspect-ratio: 9/16;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* terms and agreems  */
.terms-and-agreement {
  display: flex;
  color: #78a2b9;
  font-weight: var(--text-font-weight);
  font-size: 2dvh;
  width: 100%;
  gap: 1.8dvh;
  justify-content: flex-start;
  align-items: center;
  /* height: 3dvh; */
}
.terms-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5dvh;
  font-size: 1.666dvh;
}

.terms-link {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}

.approve-text-title {
  font-size: inherit;
  color: var(--terms-title-color);
  font-weight: var(--title-font-weight);

  /* font-weight: var(00); */
}

.approve-text {
  font-size: 1.666dvh;
  color: var(--terms-subtitle-color);
  font-weight: 400;
}

input[type='number'] {
  -moz-appearance: textfield; /* Older versions of Firefox */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
