.loading-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5vh;
  padding-top: 5dvh;
  box-sizing: border-box;
}

.loading-video-parent {
  width: 100%;
  object-fit: contain;
  border-radius: var(--border-radius);
}

.progress-container {
  direction: ltr;
}

.progress-container,
.progress {
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  height: 1.5vh;
  width: 100%;
}

.progress {
  background-color: var(--primary-color);
  width: 0;
  transition: width 0.2s linear;
}

.percentage {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  padding: 0.5dvh 0.8dvh;
  position: absolute;
  top: 2.4dvh;
  left: 0;
  transform: translateX(-50%);
  width: 3vh;
  height: 1.5vh;
  text-align: center;
  transition: left 0.2s linear;
  font-family: 'narkiss-tam', sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.percentage::after {
  font-family: 'narkiss-tam', sans-serif;
  font-weight: 700;
  background-color: var(--primary-color);
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  height: 10px;
  width: 10px;
  z-index: -1;
}
