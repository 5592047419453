.shred-gif {
  border-radius: 5%;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
  z-index: 10;
}

.overlay-screen {
  /*create a panel that overlays all of the screen, positioned at z-index 5, is white and has around 50 percent opacity, also blocks mouse 
 clicks*/
  position: fixed; /* Fixed positioning to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(255, 255, 255, 0.7); /* White color with 50% opacity */
  z-index: 5; /* Sit on top of other content */
  pointer-events: none; /* Disable mouse interaction */
}
