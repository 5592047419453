.shared-styles {
  border-radius: var(--border-radius);
  padding: 0 2em 0 2em;
  height: 8vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center; /* Aligns items to the bottom of the container */
  justify-content: center; /* Centers items horizontally */
  font-weight: 700;
  font-size: var(--button-font-size);
  cursor: pointer;
  flex: 0 1 auto;
  size: var(--button-font-size);
}

.button-content {
  display: flex;
  align-items: center; /* Aligns items to the bottom of the container */
  justify-content: center; /* Centers items horizontally */
  gap: 1.25vh;
  font-size: var(--button-font-size);
}

.standard:disabled {
  background-color: var(--color-disabled);
  pointer-events: none;
}

.inverted:disabled {
  color: var(--color-disabled);
  border-color: var(--color-disabled);
  pointer-events: none;
}

.standard {
  background-color: var(--action-button-primary);
  border: none;
  color: white;
  flex-basis: 50%;
}

.inverted {
  background-color: transparent;
  border-width: 0.5vh;
  position: relative;
  box-sizing: border-box;
  color: var(--inverted-button-color);
  background-clip: padding-box;
  border: solid 0.3vh var(--inverted-button-color);
  flex-basis: 50%;
}

.retake {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* gap: 0.9em; */
  height: 15%;
  z-index: 999;
  width: 40%;
  font-size: 1.5em;
  border: rgba(255, 255, 255, 0.7) solid 0.3vh;
  color: rgba(255, 255, 255, 0.7);
  border-collapse: separate;
  border-radius: var(--border-radius);
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 50%);
}
