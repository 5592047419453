.headline {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 12dvh;
  width: 100%;
  justify-content: center;
  box-shadow: 0px 0.1dvh 0.6dvh rgba(0, 0, 0, 0.5); /* Horizontal offset, vertical offset, blur radius, shadow color */
  z-index: 10;
}
.full-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
}

.logo-headline {
  height: 5.5dvh;
}
.b-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.6652dvh;
}
.parental-consent-text-container {
  padding: 3.6652dvh;
  box-sizing: border-box;
  gap: 1.83326dvh;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  direction: rtl;
}

.parental-consent-title {
  /* font-size: var(--title-font-size); */
  font-size: 3.6652dvh;
  font-weight: 700;
  color: #354851;
}

.parental-consent-subtitle {
  font-size: 1.7rem;
  color: #354851;
  font-weight: 500;
}

.parental-consent-text {
  font-weight: 400;
  font-family: var(--font-family);
  /* font-size: var(--text-font-size); */
  color: #7c8d94;
  font-size: 1.3rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.title-container {
  display: flex;
  flex-direction: column;
  gap: 0.892dvh;
  direction: rtl;
}
.forms-section {
  font-weight: 500;
  direction: rtl;
  font-size: 3.125dvh;
  box-sizing: border-box;
  padding: 3.6652dvh;
  gap: 1.5dvh;
  display: flex;
  flex-direction: column;
}

.checkbox-section {
  font-weight: 500;
  direction: rtl;
  font-size: 3.125dvh;
  box-sizing: border-box;
  padding: 3.6652dvh;
  gap: 3.83326dvh;
  display: flex;
  flex-direction: column;
}

.card-parental {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  border-radius: var(--border-radius);
  opacity: 1;
  box-shadow: 0 0vh 1vh rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: white;
  border-radius: 1.5vh;
  padding: 2vh;
  height: 55dvh;
  aspect-ratio: 1/1.32;
  gap: 3vh;
  box-sizing: border-box;
}
.details-parental {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1vh;
}
.card-title-parental {
  color: var(--title-font-color);
  font-size: 4dvh;
  font-weight: var(--title-font-weight);
}
.card-text-parental {
  color: var(--text-font-color);
  opacity: var(--text-opcaity);
  font-weight: var(--text-font-weight);
  font-size: 1.3rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.gallery-image-parental {
  cursor: pointer;
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 1.5vh;
  /* Ensures the images cover the cell area without distortion */
}
