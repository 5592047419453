.navigation-toolbar {
  display: flex;
  font-family: 'Inter';
  justify-content: space-between;
  align-items: center;
  height: 9vh;
  z-index: -1;
  width: 30vh;
  padding: 3vh;
  box-sizing: border-box;
  position: absolute;
  z-index: -1;
  top: 0;
}

.navigation-toolbar-ghost {
  box-sizing: border-box;
  top: 0;
  height: 9vh;
  position: relative;
  z-index: -1;
}

navigation-toolbar-ghost,
navigation-toolbar-ghost ::before,
navigation-toolbar-ghost ::after {
  box-sizing: border-box;
}
.line {
  width: 100%;
  height: 0.2rem;
  background-color: var(--primary-color);
}

.dotted {
  border-top-width: 0.2rem;
  border-top-style: dotted;
  width: 100%;
  background-color: transparent;
}

.navigation-button {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  padding: 0;
  align-items: center;
  font-size: 1.5vh;
  justify-content: center;
  border-width: 0.3vh;
  border-style: solid;
  background-color: transparent;
}

.navigation-button.completed {
  border-color: #78a2b9;
  background-color: #78a2b9;
  color: #78a2b9;
}

.navigation-button.selected {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.navigation-button.idle {
  border-color: #e7f1f6;
  color: #e7f1f6;
  background-color: transparent;
}
