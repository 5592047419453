input[type='checkbox']:checked + .custom-checkbox-label::after {
  transform: scale(1) rotate(45deg);
}

input[type='checkbox']:checked + .custom-checkbox-label {
  background-color: var(--primary-color);
}

.custom-checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3dvh;
  aspect-ratio: 1/1;
  background-color: var(--primary-color-extra-light);
  border: 2px solid #78a2b9;
  cursor: pointer;
  position: relative;
  border-radius: var(--border-radius);
}
.custom-checkbox-label svg {
  width: 1.8dvh;
}

/* Hiding the native checkbox */
input[type='checkbox'] {
  display: hidden;
}
