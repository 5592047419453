.end-card-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
  box-shadow: 0 0 2vh rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);
  padding: 4.1666dvh 2.29dvh;
  box-sizing: border-box;
}

.end-card {
  border-radius: var(--border-radius);
  object-fit: cover;
  aspect-ratio: 1/1;
}
.end-details-parent {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.end-card-title {
  color: var(--title-font-color);
  font-size: 2.8vh;
  font-weight: var(--title-font-weight);
}
.end-card-text {
  color: var(--text-font-color);
  opacity: var(--text-opcaity);
  font-weight: var(--text-font-weight);
  font-size: var(--text-font-size);
}
.icon-parent {
  background-color: #eaddff;
  border-radius: 50%;
  width: 1.3rem;
  aspect-ratio: 1/1;
  padding: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-button {
  cursor: pointer;
  background-color: var(--action-button-primary);
  border: solid transparent;
  color: #f4f4f4;
  font-weight: 700;
  font-size: var(--button-font-size);
  border-radius: var(--border-radius);
  line-height: 2.5vh;
  display: flex;
  justify-content: center;
  gap: 1.2vh;
  align-items: flex-end; /* Align items to the bottom */
  flex-direction: row;
  padding: 1.5rem;
  height: 100%;
  width: 100%;
}

.email-success-container {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  width: 80%;
}

.text-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 300;
  color: #3c3c4399;
  font-size: 1.5rem;
}
