.header-container {
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 300;
  width: 100%;
  height: auto;

  gap: 1vh;
}

.header-ghost {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 300;
  width: 100%;
}

.title {
  /* font-family: 'Roboto', sans-serif; */
  font-size: var(--title-font-size);
  color: var(--title-font-color);
  font-weight: var(--title-font-weight);
  line-height: var(--line-height); /*or 5.8dvh*/
  letter-spacing: var(--letter-spacing);
}

.text {
  /* font-family: 'Roboto', sans-serif; */
  font-size: var(--text-font-size);
  font-weight: var(--text-font-weight);
  color: var(--text-font-color);
  opacity: var(--text-opcaity);
}
