.button-container {
  display: flex;
  flex-direction: column;
  gap: 3.333dvh;
  /* box-sizing: border-box; */
  height: 8dvh;
  padding-top: 8.333dvh;
}
.horizontal-line {
  width: 100%;
  height: 0.83dvh; /* Adjust the height (thickness) of the line */
  background-color: #d0d9dd; /* Change to your desired color */
}
.passcode-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4.166dvh;
}
.passcode-blocks-parent {
  display: flex;
  direction: ltr;
  gap: 2.91666dvh;
  font-size: var(--text-font-size);
  font-weight: 400;
}
.passcode-block {
  width: 100%;
  aspect-ratio: 1/1;
  border: 0.3083dvh solid #d0d9dd; /* Combined border color and width */
  border-radius: var(--border-radius);
  color: #929fa5;
  padding: 0;
  margin: 0;
  font-size: 1dvh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passcode-block:focus {
  border-color: var(--secondary-color); /* Change border color on focus */
  outline: none; /* Remove default focus outline if desired */
}
