.portrait-viewer {
  border-radius: 5%;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.portrait-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1 / 1;
  z-index: 999;
}

.selection-parent {
  gap: 3vh;
  display: flex;
  flex-direction: column;
}
