.photo-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.6dvh;
  height: 16.6dvh;
  border-radius: 50%;
  background-color: transparent;
  color: var(--primary-color);
  border: 0.1rem solid var(--primary-color);
}
.timer-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0%; /* Centers the top of the element at the middle of the viewport */
  transform: translateY(22.25vh);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
