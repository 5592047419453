.input-email-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2vh;
}
.forms-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.666vh;
}

.black-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 200vw;
  height: 200dvh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forms-bundle {
  width: 100%;
  display: flex;
  gap: 1.666vh;
}

.form-title {
  font-size: 1.1rem;
  width: 100%;
  padding: 0 1.5vh;
  gap: 0.26dvh;
  height: 2.2dvh;
  display: flex;
  box-sizing: border-box;
  color: var(--title-font-color);
  border-radius: var(--border-radius);
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-title::before {
  color: #f36666;
  font-size: 0.9rem;
}

.not-mandatory {
  font-size: 1.1rem;
  width: 100%;
  box-sizing: border-box;
  color: var(--title-font-color);
  content: '';
  padding: 0 1vh;
}

.form-package {
  width: 100%;
  gap: 0.625vh;
  display: flex;
  flex-direction: column;
}

/* terms and conditions */
