.front-image {
  width: 100%;
  border-radius: var(--border-radius);
}

.logo {
  width: 13%;
}

.homepage-text {
  font-weight: var(--text-font-weight);
  font-size: var(--text-font-size);
  line-height: 1.9rem;
  opacity: var(--text-opcaity);
}

.homepage-text-container {
  display: flex;
  flex-direction: column;
  gap: 1dvh;
}

.homepage-container {
  display: flex;
  z-index: 999;
  position: absolute;
  transform: translate(0, 43%);
  width: var(--parent-container-width);
  flex-direction: column;
  justify-content: center;
  gap: 3dvh;
}
.front-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-button-text {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.7dvh;
}

.homepage-header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  direction: ltr;
}
.language-selection-container {
  display: flex;

  flex-direction: row;
  justify-content: space-evenly;
  gap: 0.2em;
}
.language-select {
  cursor: pointer;
  font-size: 2.3dvh;
  text-transform: uppercase;
  color: #adadc299;
}
.selected {
  color: #42718b;
  font-weight: 600;
}
.homepage-title {
  color: var(--text-font-color);
  font-weight: var(--title-font-weight);
  font-size: var(--title-font-size);
}
