.login-parent {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  padding: 2vh;
}
.login-buttons {
  border-radius: var(--border-radius);
  padding: 0 2em 0 2em;
  height: 8vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center;
  font-weight: 700;
  font-size: var(--button-font-size);
  cursor: pointer;
  flex: 0 1 auto;
}
